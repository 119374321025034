<template>
    <WidgetWrapper 
        :widget="widget" 
        ref="WidgetWrapper"
        :class="isMobile && 'mobile_widget'">
        <div class="scroll">
            <div v-if="!related_object" class="comment_empty">
                <i class="fi fi-rr-settings-sliders"></i>
                <p>Для отображение комментариев необходимо установить связь</p>
                <a-button type="ui" size="small" @click="openSetting()">
                    Настройки
                </a-button>
            </div>
            <vue2CommentsComponent 
                v-else
                :key="related_object"
                :modalContainer="false"
                :related_object="related_object" />
        </div>
    </WidgetWrapper>
</template>

<script>
import vue2CommentsComponent from '@apps/vue2CommentsComponent'
import WidgetWrapper from '../WidgetWrapper.vue'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        WidgetWrapper,
        vue2CommentsComponent
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        related_object() {
            return this.widget.random_settings?.related_object?.id || null
        }
    },
    methods: {
        openSetting() {
            eventBus.$emit('openSetting', this.widget)
        }
    }
}
</script>

<style lang="scss" scoped>
.scroll{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.mobile_widget{
    .scroll{
        height: 350px;
    }
}
.comment_empty{
    text-align: center;
    padding-top: 20px;
    i{
        font-size: 42px;
        color: var(--gray);
    }
    p{
        margin-top: 15px;
        margin-bottom: 20px;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>